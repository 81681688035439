import {
    Accordion, Breadcrumb,
    Button,
    Col,
    Container,
    Image,
    ListGroup,
    Nav,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import {Setting, webSetting} from "../../helper/setting";
import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Moment from "moment";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {getUrl, setTitle} from "../../helper/function";
import nl2br from "react-nl2br";
import BreadCrumb from "../../component/breadcrumb";
import Url from "../../component/links";

function Department(){
    let {id} = useParams()
    const t = useTranslation()
    const [loading, setLoading] = useState(false);
    const [servicesData, setData] = useState([]);
    const [selectedKey,setselectedKey] = useState(-1)
    const [selectedData , setselectedData] = useState([]);
    const [tag , setTag] = useState('');

    const [loading2, setLoading2] = useState(false);

    const fetchDta = async () => {
        setLoading(true);
        axios.get(Setting.api + 'PublicMuniDepartmentCms/Getall/Getall',
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data);
            const index =   response.data.findIndex( (array:any) => {
                return parseInt(array['id']) === parseInt(id as string)
            })
            //console.log(index)
            if(index >= 0){
                setselectedKey(index)
            }
            setLoading(false);

        }).catch(function (error) {

        });
    };


    const getData = async (did:any) => {
        setLoading2(true)

        setTag('')
        const index =   servicesData.findIndex( (array:any) => {
            return parseInt(array['id']) === parseInt(did as string)
        })
        console.log(index)
        if(index >= 0){
            setselectedKey(index)
        }


        axios.get(Setting.api + 'PublicMuniDepartmentCms/Get/Get/'+did,
            {
                method: "get",
                headers: {
                    'Accept-Language': t.i18n.language,
                    'accept': 'text/plain',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("access_token")
                }
            }
        ).then(async function (response) {
            setData(response.data.list);
            setselectedData(response.data);
            setLoading2(false)
            document.title = webSetting.Title + " - " + response.data.department.name

            //setLoading(false);
        }).catch(function (error) {

        });
    };


    useEffect(()=>{
        //fetchDta();
    },[localStorage.getItem("lang") ])

    useEffect(()=>{
        getData(id)
    },[localStorage.getItem("lang") ,id])



    const infoData = JSON.parse(JSON.stringify(selectedData))

    return(
        <div id={"contentRow"}>
            {loading ? <div className={"text-center py-5"}><Spinner animation={"border"} variant={"warning"}
                                                                    title={t.t("pleaseWait")}/></div> :

                <>

                    <Container className={"my-3"} >

                    {selectedKey >=0 &&
                    <Row>

                        <Col md={3}>

                        </Col>
                        <Col md={9}>
                            {selectedKey >= 0 &&
                            <div className={"color2"}>
                                <h3 className={"text-center c"}>{servicesData[selectedKey]['name']}</h3>
                            </div>
                            }
                        </Col>
                    </Row>
                    }

                    <Row  >
                        <Col md={3} >
                            <div className={"bg-white"}>

                                <ListGroup className={"serviceCat border-0"}>
                                    {
                                        servicesData.map((value, key) => {
                                            return (
                                                <ListGroup.Item key={"d"+value['id']} as={Link} to={"/department/" + value['id']+"/"+setTitle(value['name'])}
                                                                className={value['id'] == id ? "active " : "color2"}
                                                                >
                                                    {value['name']}
                                                </ListGroup.Item>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </div>
                        </Col>
                        <Col md={9}>
                            {loading2 ?
                                <div className={"text-center py-5"}><Spinner animation={"border"} variant={"warning"}
                                                                             title={t.t("pleaseWait")}/></div> :

                                <div>
                                {
                                    infoData.department &&
                                        <div className={"color2"}>
                                            {(infoData.department.telephoneList.length > 0 || (infoData.department.email || infoData.department.phone || infoData.department.fax)) &&
                                            <div className={"serviceInfo"}>
                                                {(infoData.department.email || infoData.department.phone || infoData.department.fax) &&
                                                <Row className={"py-4"}>
                                                    {infoData.department.phone &&
                                                    <Col md={4} xs={12}>
                                                        <div className={"text-center"}>
                                                            <div className={"bold h4"}>{t.t("phone")}</div>
                                                            <div className={"h5"}>{infoData.department.phone}</div>
                                                        </div>
                                                    </Col>
                                                    }
                                                    {infoData.department.fax &&
                                                    <Col md={4} xs={12}>
                                                        <div className={"text-center"}>
                                                            <div className={"bold h4"}>{t.t("fax")}</div>
                                                            <div className={"h5"}>{infoData.department.fax}</div>
                                                        </div>
                                                    </Col>
                                                    }
                                                    {infoData.department.email &&
                                                    <Col md={4} xs={12}>
                                                        <div className={"text-center"}>
                                                            <div className={"bold h4"}>{t.t("email")}</div>
                                                            <div
                                                                className={"h5"}>{infoData.department && infoData.department.email}</div>
                                                        </div>
                                                    </Col>
                                                    }
                                                </Row>
                                                }
                                                {infoData.department.telephoneList.length > 0 &&
                                                <Row>
                                                    <Col md={12}>
                                                        <div>
                                                            <Accordion defaultActiveKey="0">
                                                                <Accordion.Item eventKey="1" className={"border-0"}
                                                                                style={{backgroundColor: "transparent"}}>
                                                                    <Accordion.Header
                                                                        className={"d-inline-block py-0"}>{t.t("contact")}</Accordion.Header>
                                                                    <Accordion.Body style={{backgroundColor: "#fff"}}>
                                                                        <Table responsive={true}>
                                                                            <thead>
                                                                            <tr className={"h6  color4"}>
                                                                                <th>{t.t("fullname")}</th>
                                                                                <th>{t.t("position")}</th>
                                                                                <th>{t.t("phone")}</th>
                                                                                <th>{t.t("email")}</th>
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody className={"color4 h6 normal"}
                                                                                   style={{borderTop: 0}}>
                                                                            {
                                                                                infoData.department.telephoneList.map((value: any) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{value['empName']}</td>
                                                                                            <td>{value['empPosition']}</td>
                                                                                            <td><>{value['phone'] != '' ? <a href={"tel:"+value['phone']}>{value['phone']}</a> : "" }</></td>
                                                                                            <td><>{value['email'] != '' ? <a href={"mailto:"+value['email']}>{value['email']}</a> : "" }</></td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }


                                                                            </tbody>
                                                                        </Table>

                                                                    </Accordion.Body>
                                                                </Accordion.Item>

                                                            </Accordion>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                }
                                            </div>
                                            }
                                            <div>
                                                <p className={"h4 mt-4 pe-2"}
                                                   style={{borderRight: "5px solid #05324a"}}>{t.t("aboutDepartment")}</p>
                                                <p className={"text-justify"}>
                                                    {infoData.department.description && nl2br(infoData.department.description) }
                                                </p>
                                            </div>
                                            <div>
                                                {infoData.department.tags &&
                                                <Row className={"my-2"}>



                                                    <Col md={12}>
                                                        <Nav className={"tags flex-nowrap h-100"} >
                                                            {
                                                                infoData.department.tags.map((value:any)=>{
                                                                    return(
                                                                            <Nav.Item  className={" w-100 h-100 "}  key={"kk" + value.id} as={"li"}>
                                                                                <Nav.Link className={ "bg-white d-inline-flex h-100 darkBtn w-100 p-2 shadow-lg   " + (value.description != "" && "searchLink")} eventKey={"tab" + value.id}
                                                                                             onClick={()=>{setTag(value.description)}}>{value.tag}</Nav.Link>
                                                                             </Nav.Item>
                                                                        )
                                                                })
                                                                }
                                                         </Nav>

                                                    </Col>


                                                    <Col className={"my-3"} md={12} >
                                                        {nl2br(tag)}
                                                    </Col>
                                                </Row>
                                                }


                                                <Row className={" text-center justify-content-center"}>
                                                    {infoData.department.formUrl &&
                                                    <Col className={"my-5"} xs={6} md={3}>
                                                        <a href={infoData.department.formUrl}
                                                           style={{color: "inherit"}}>
                                                            <div className={"mbox mbox2 py-3 bg-yallow"}>
                                                                <Image srcSet={Setting.sysUrl + "images/svg/a2.svg"}
                                                                       alt={""}
                                                                       style={{height: 50}}/>
                                                                <p>{t.t("formUrl")}</p>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                    }
                                                    {infoData.department.paymentUrl &&
                                                    <Col className={"my-5"} xs={6} md={3}>
                                                        <a href={infoData.department.paymentUrl}
                                                           style={{color: "inherit"}}>
                                                            <div className={"mbox mbox2 py-3 bg-yallow"}>
                                                                <Image srcSet={Setting.sysUrl + "images/svg/a4.svg"}
                                                                       alt={""}
                                                                       style={{height: 50}}/>
                                                                <p>{t.t("paymentUrl")}</p>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                }
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
                </>
            }
        </div>
        )
}
export default Department
